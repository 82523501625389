@tailwind base;
@tailwind components;

@import "~react-image-gallery/styles/css/image-gallery.css";

@font-face {
  font-family: "hagerman";
  src: local("hagerman"), url(../public/assets/fonts/hagerman-Regular.ttf);
}

@font-face {
  font-family: "argent";
  src: local("argent"), url(../public/assets/fonts/argent-cf-super.otf);
}

@font-face {
  font-family: "marcia";
  src: local("marcia"), url(../public/assets/fonts/marcia-bold.otf);
  font-weight: bold;
}

@font-face {
  font-family: "marcia";
  src: local("marcia"), url(../public/assets/fonts/marcia-regular.otf);
}

@tailwind utilities;
